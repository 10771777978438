import { FormGroup, Form, FloatingLabel } from "react-bootstrap";
import propTypes from "prop-types";
import DatePicker from "react-datepicker";
import Select from "react-select";

function Editable(props) {
  Editable.propTypes = {
    name: propTypes.string,
    value: propTypes.string,
    onChange: propTypes.func,
    label: propTypes.string,
    editable: propTypes.bool,
    type: propTypes.string,
    options: propTypes.array
  };

  const renderInput = () => {
    switch (props.type) {
      case "date":
        return (
          <DatePicker
            className="form-control mt-1 custom-date-picker"
            selected={props.value}
            onChange={props.onChange}
          />
        );
      case "textarea":
        return (
          <Form.Control
            as="textarea"
            name={props.name}
            className="mt-1"
            value={props.value}
            onChange={props.onChange}
            rows="3"
          />
        );
      case "file":
        return <Form.Control type="file" name={props.name} onChange={props.onChange} />;
      case "select":
        return (
          <Select
            className="mt-1"
            options={props.options}
            onChange={props.onChange}
            value={props.value}
          />
        );
      default:
        return (
          <Form.Control
            type="text"
            name={props.name}
            className="mt-1"
            value={props.value}
            onChange={props.onChange}
          />
        );
    }
  };
  return <>{props.editable === true ? renderInput() : <span>{props.value}</span>}</>;
}

export default Editable;
