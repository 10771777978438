import React, { createContext, useEffect, useState } from "react";
import Profile from "../services/Profile";
import Cookies from "js-cookie";
import propTypes from "prop-types";

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  ProfileProvider.propTypes = {
    children: propTypes.any
  };
  const [profile, setProfile] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  let token = "";
  const login = (token) => {
    setIsLoggedIn(true);
    setAccessToken(token);
  };

  const logout = () => {
    setIsLoggedIn(false);
    setAccessToken(null);
    setProfile({});
  };

  const getProfile = async () => {
    const data = await Profile.getMyProfile();
    if (data) {
      setProfile(data?.data?.data);
    } else {
      setProfile({});
    }
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("login")) {
      token = "";
    } else {
      token = Cookies.get("authToken");
    }
    if (token) {
      getProfile();
    }
    if (isLoggedIn && accessToken) {
      getProfile();
    }
  }, [isLoggedIn, accessToken, token]);

  return (
    <ProfileContext.Provider value={{ profile, isLoggedIn, accessToken, login, logout }}>
      {children}
    </ProfileContext.Provider>
  );
};
