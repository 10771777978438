import Profile from "../../../services/Profile";
import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import changeDateFormat from "../../../Components/DateFormat";

import { Alert, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import Patients from "../../../services/Patients";
import Editable from "../../Components/Editable";
import moment from "moment";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import FilesUpload from "../../../services/FilesUpload";
import FilesList from "./FilesList";
import { Link } from "react-router-dom";

const Visits = () => {
  const [editableIndex, setEditableIndex] = useState(null);
  const [visit, setVisit] = useState({});
  const [doctors, setDoctors] = useState([]);
  const [fileModal, setFileModal] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const [files, setFiles] = useState([]);

  const { id } = useParams();
  const navigate = useHistory();

  const { data } = useQuery(
    ["visitsList", id],
    () => (id ? Patients.getVisitsByPatientId(id) : Patients.getVisits()),

    {
      refetchOnWindowFocus: false
    }
  );

  const visits = data?.visits;
  const getDoctorList = async (visitorData) => {
    const data = await Profile.getDoctors();
    if (data) {
      const doctorList = data.map((doctor) => {
        if (doctor.id === visitorData.doctorId) {
          setVisit({
            ...visitorData,
            doctor: {
              value: visitorData.doctorId,
              label: doctor.name
            },
            nextVisit: moment(visitorData.nextVisit).toDate()
          });
        }
        return { value: doctor.id, label: doctor.name };
      });
      setDoctors(doctorList);
    }
  };

  const handleVisitEdit = async (event, visitorData, index) => {
    event.preventDefault();
    // if (editableIndex === index) {
    //   const visitData = { ...visit, doctorId: visit?.doctor?.value };
    //   const data = await Patients.addVisit(visitData);
    //   if (data?.status === 200 || data?.status === 201) {
    //     queryClient.invalidateQueries("visitsList");
    //     setEditableIndex(null);
    //   }
    // } else {
    //   setEditableIndex(index);
    //   getDoctorList(visitorData);
    // }
    navigate.push("/patients/visit/edit/" + id + "/" + visitorData._id);
  };

  const changeVisit = (e) => {
    const { name, value } = e.target;
    setVisit((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleUploadImage = async (e, name, visitData) => {
    e.preventDefault();
    const fileInput = e.target;
    const file = fileInput.files[0];

    if (file) {
      const fileData = await FilesUpload.upload(id ? id : visitData?.patient?._id, file);
      if (fileData?.data) {
        setVisit((prevData) => ({
          ...prevData,
          [name]: [...prevData[name], fileData.data.fileUrl]
        }));
      }
    }
  };

  const viewFile = (event, files, fieldName) => {
    event.preventDefault();
    setFileModal(true);
    setFiles(files);
    setFieldName(fieldName);
  };
  return (
    <Row>
      {visits?.length > 0 ? (
        visits &&
        visits.map((visitData, i) => (
          <Col className="col-md-6 vist-card" key={i}>
            <Card>
              <Card.Header className="d-flex justify-content-between align-items-center pb-1">
                <span>
                  {" "}
                  {i + 1}. {changeDateFormat(visitData.createdAt, !id ? true : false, true)}
                </span>

                <div>
                  <Button
                    variant="btn btn-outline-primary"
                    onClick={(event) => handleVisitEdit(event, visitData, i)}
                  >
                    {editableIndex === i ? (
                      <FontAwesomeIcon icon={faSave} />
                    ) : (
                      <FontAwesomeIcon icon={faEdit} />
                    )}
                  </Button>
                </div>
              </Card.Header>
              <Card.Body>
                <Table responsive striped>
                  <tbody>
                    {!id && (
                      <tr>
                        <td width="150">Patient Name</td>

                        <th>
                          <Link
                            className="info-card-link"
                            to={"/patients/patient/" + visitData?.patient?._id}
                          >
                            {visitData?.patient?.name}
                          </Link>
                        </th>
                      </tr>
                    )}
                    <tr>
                      <td width="150">Doctor Name</td>
                      <td>
                        <Editable
                          name="doctorName"
                          value={editableIndex === i ? visit?.doctor : visitData?.doctor?.name}
                          editable={editableIndex === i}
                          onChange={(selectedOption) =>
                            setVisit({ ...visit, doctor: selectedOption })
                          }
                          type="select"
                          options={doctors}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Visited Date</td>
                      <td>{changeDateFormat(visitData.createdAt, false)}</td>
                    </tr>
                    <tr>
                      <td>Symptoms</td>
                      <td>
                        <Editable
                          name="symptoms"
                          value={editableIndex === i ? visit.symptoms : visitData.symptoms}
                          editable={editableIndex === i}
                          onChange={changeVisit}
                          type="textarea"
                          rows="3"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Prescriptions</td>
                      <td>
                        <Editable
                          name="prescriptions"
                          value={
                            editableIndex === i ? visit.prescriptions : visitData.prescriptions
                          }
                          editable={editableIndex === i}
                          onChange={changeVisit}
                          type="textarea"
                          rows="3"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Next Visit</td>
                      <td>
                        <Editable
                          name="date"
                          value={
                            editableIndex === i
                              ? visit.nextVisit
                              : changeDateFormat(visitData.nextVisit, false)
                          }
                          editable={editableIndex === i}
                          onChange={(date) => setVisit({ ...visit, nextVisit: date })}
                          type="date"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Files</td>
                      <td>
                        <Editable
                          name="files"
                          value={
                            editableIndex === i ? (
                              ""
                            ) : (
                              <Button
                                onClick={(event) =>
                                  viewFile(event, visitData.files, "patientFiles")
                                }
                                size="sm"
                                variant="btn btn-outline-primary"
                              >
                                View
                              </Button>
                            )
                          }
                          editable={editableIndex === i}
                          onChange={(e) => handleUploadImage(e, "files", visitData)}
                          type="file"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Prescription Files</td>
                      <td>
                        <Editable
                          name="prescriptionFiles"
                          value={
                            editableIndex === i ? (
                              ""
                            ) : (
                              <Button
                                onClick={(event) =>
                                  viewFile(event, visitData.prescriptionFiles, "prescriptionFiles")
                                }
                                size="sm"
                                variant="btn btn-outline-primary"
                              >
                                View
                              </Button>
                            )
                          }
                          editable={editableIndex === i}
                          onChange={(e) => handleUploadImage(e, "prescriptionFiles", visitData)}
                          type="file"
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        ))
      ) : (
        <Alert variant="warning">Data Not Found!!</Alert>
      )}
      <FilesList
        show={fileModal}
        onHide={() => setFileModal(false)}
        files={files}
        fieldName={fieldName}
      />
    </Row>
  );
};

export default Visits;
