import React from "react";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import propTypes from "prop-types";
import { Alert } from "react-bootstrap";

function FilesList(props) {
  FilesList.propTypes = {
    onHide: propTypes.func,
    show: propTypes.bool,
    files: propTypes.array,
    fieldName: propTypes.string
  };

  return (
    <Modal {...props} size="lg">
      <Modal.Header closeButton>
        <div>{props.fieldName === "patientFiles" ? "Files" : "Prescription Files"}</div>
      </Modal.Header>
      <Modal.Body>
        {props.files?.length === 0 ? (
          <Alert variant="info">No files found</Alert>
        ) : (
          <ListGroup as="ol" numbered>
            {props?.files?.map((file, i) => {
              if (file !== "") {
                // eslint-disable-next-line no-useless-escape
                const regex = /_patient_([^\/]+(\.[^\/.]+))$/;
                const match = file.match(regex);
                return (
                  <ListGroup.Item key={i + 1} as="li">
                    <a href={file}> {match && match[1] ? match[1] : file}</a>
                  </ListGroup.Item>
                );
              }
            })}
          </ListGroup>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default FilesList;
