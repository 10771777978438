import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Card, Button, Form, Container } from "react-bootstrap";
import Login from "../../services/Login";
import Cookies from "js-cookie";
import { ProfileContext } from "../../contexts/ProfileContext";
import ForgotPassword from "../ForgotPassword/ForgotPassword";

export default function LoginPage() {
  const { login } = useContext(ProfileContext);
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [openForgotModal, setopenForgotModal] = useState(false);
  const [password, setPassword] = useState("");
  const navigate = useHistory();

  const sentPhoneOtp = async () => {
    //navigate.push("user-management/doctors");
    const data = await Login.requestPhoneOtp({
      phone: "+91" + phone
    });
    if (data?.status === 200) {
      setIsOtpSent(true);
    }
  };
  const verifyOtp = async () => {
    const data = await Login.verifyPhoneOtp({
      phone: "+91" + phone,
      otp
    });
    if (data?.status === 200) {
      const path = Cookies.get("path");
      const accessToken = Cookies.get("authToken");
      await login(accessToken);
      if (path !== "") {
        navigate.push(path);
      } else setError("You are not authorized");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = await Login.userLogin({
      phone: "+91" + phone,
      password
    });
    if (data?.status === 200) {
      const path = Cookies.get("path");
      const accessToken = Cookies.get("authToken");
      await login(accessToken);
      if (path !== "") {
        navigate.push(path);
      } else setError("You are not authorized");
    }
  };
  return (
    <>
      {openForgotModal ? (
        <ForgotPassword openForgotModal={openForgotModal} setopenForgotModal={setopenForgotModal} />
      ) : null}
      <Container
        fluid
        className="bg-primary-gradient d-flex align-items-center flex-column justify-content-center h-100 login-container"
      >
        <Card className="p-5" id="login-card">
          <img alt="" height="auto" src={process.env.PUBLIC_URL + "/images/logo.svg"} />
          <Form className="mt-5">
            <Form.Group>
              <Form.Label for="exampleEmail">Phone Number</Form.Label>
              <Form.Control
                id="phone"
                name="phone"
                disabled={isOtpSent ? "disabled" : null}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Enter your phone number"
                type="number"
                value={phone}
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label for="password">Password</Form.Label>
              <Form.Control
                id="password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
                type="password"
                value={password}
              />
            </Form.Group>

            {error ? <p className="validation-error mt-2 mb-0">{error}</p> : null}
            <Button
              className="btn login-btn mt-5 w-100"
              disabled={phone === "" || password === "" ? true : false}
              onClick={handleSubmit}
              type="submit"
            >
              Submit
            </Button>
          </Form>
        </Card>
        <p className="text-center">Copyright © 2024 Cliniment. All rights reserved.</p>
      </Container>
    </>
  );
}
