import { coreApi, endPoints } from "../config/clinimentApi";
import { ClinimentAuth } from "./ClinimentAuth";
import errorHandling from "./ErrorHandling";

const Profile = {
  getMyProfile: async () => {
    try {
      const response = await ClinimentAuth.fetchData(coreApi + endPoints.myProfile, "get");
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
    }
  },

  getUsers: async (page, queryData) => {
    const queryParams = {
      page: page.page,
      limit: page.limit
    };
    let query = "?page=" + (queryParams.page + 1) + "&limit=" + queryParams.limit;

    if (queryData.name) {
      query += "&name=" + queryData.name;
    }
    if (queryData.phone) {
      let phone = queryData.phone;
      if (phone.startsWith("+")) {
        phone = queryData.phone.slice(1);
      }
      query += "&phone=" + phone;
    }
    if (queryData.role) {
      query += "&role=" + queryData.role;
    }

    try {
      const response = await ClinimentAuth.fetchData(
        coreApi + endPoints.getAllUsers + query,
        "get"
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
    }
  },

  getUserByUserId: async (id) => {
    try {
      const response = await ClinimentAuth.fetchData(endPoints.getUser + "?userId=" + id, "get");
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err.response.status);
    }
  },

  getDoctors: async () => {
    try {
      const response = await ClinimentAuth.fetchData(
        coreApi + endPoints.getUsersForDropdown + "?role=doctor",
        "get"
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
    }
  }
};

export default Profile;
