import React, { Fragment } from "react";
import VisitList from "./Visits";
import PageTitle from "../../../Layout/AppMain/PageTitle";
// import VisitFilters from "./VisitFilters";

const Visits = () => {
  return (
    <Fragment>
      <PageTitle
        heading="Manage Visits"
        subheading="Manage Visits."
        icon="metismenu-icon pe-7s-users"
      />
      {/* <VisitFilters /> */}
      <VisitList />
    </Fragment>
  );
};

export default Visits;
