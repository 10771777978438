import React from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import propTypes from "prop-types";

function PrivateRoute({ component: Component, ...rest }) {
  const authToken = Cookies.get("authToken");
  PrivateRoute.propTypes = {
    component: propTypes.any
  };
  return (
    <Route
      {...rest}
      render={(props) => (authToken ? <Component {...props} /> : <Redirect to="/login" />)}
    />
  );
}

export default PrivateRoute;
