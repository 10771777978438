import routes from "./RoutePaths";

const submenuItems = {
  users: {
    label: "Users",
    to: "#/user-management/users",
    className: "text-secondary"
  }
};

const menuItems = {
  cliniment: {
    name: "Cliniment",
    //name: "Mumfy",
    type: "heading"
  },
  patients: {
    type: "mainMenu",
    icon: "pe-7s-users",
    label: "Patients",
    to: "#/patients/patients"
  },
  visits: {
    type: "mainMenu",
    icon: "pe-7s-users",
    label: "Visits",
    to: "#/visits"
  },
  contacts: {
    type: "mainMenu",
    icon: "pe-7s-users",
    label: "Contacts",
    to: "#/contacts"
  },
  userManagement: {
    type: "mainMenu",
    icon: "pe-7s-users",
    label: "User Management"
  },
  settings: {
    type: "mainMenu",
    icon: "pe-7s-config",
    label: "Settings",
    to: "#/settings-list/settings"
  }
};

const allMenu = [
  {
    item: menuItems.cliniment,
    routes: routes.allMenuRoutes,
    mainMenu: [
      {
        ...menuItems.patients
      },
      {
        ...menuItems.visits
      },
      {
        ...menuItems.contacts
      },
      {
        ...menuItems.userManagement,
        content: [submenuItems.users]
      },
      {
        ...menuItems.settings
      }
    ]
  }
];

const doctorMenu = [
  {
    item: menuItems.cliniment,
    routes: routes.allMenuRoutes,
    mainMenu: [
      {
        ...menuItems.patients
      },
      {
        ...menuItems.visits
      },
      {
        ...menuItems.contacts
      },
      {
        ...menuItems.userManagement,
        content: [submenuItems.users]
      },
      {
        ...menuItems.settings
      }
    ]
  }
];

export const Cliniment = {
  allMenu,
  doctorMenu
};
