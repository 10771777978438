import React, { Fragment, useEffect, useState, useRef } from "react";
import cx from "classnames";
import { Form, ListGroup, Badge } from "react-bootstrap";
import Patients from "../services/Patients";
import changeDateFormat from "./DateFormat";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const SearchBox = () => {
  const navigate = useHistory();
  const [activeSearch] = useState(true);
  const [patients, setPatients] = useState([]);
  const [linkClicked, setLinkClicked] = useState(false);
  const [showPatientList, setShowPatientList] = useState(false);
  const patientListRef = useRef(null);
  const inputRef = useRef(null);

  const getPatients = async (patientName) => {
    const pageDetails = {
      page: 1,
      limit: 10
    };
    const query = {
      name: patientName
    };
    const patients = await Patients.getPatients(pageDetails, query);
    if (patients?.patients) {
      setPatients(patients.patients);
    }
  };

  const changePatientName = (event) => {
    setLinkClicked(false);
    if (event.target.value.length > 0) {
      getPatients(event.target.value);
      setShowPatientList(true);
    } else {
      clickPatient();
      setShowPatientList(false);
    }
  };

  const clickPatient = async () => {
    setLinkClicked(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        patientListRef.current &&
        !patientListRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setShowPatientList(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  console.log(patients);
  return (
    <Fragment>
      <div
        className={cx("search-wrapper", {
          active: activeSearch
        })}
      >
        <div className="input-holder">
          <Form>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Enter Patient Name"
                className="search-input"
                onChange={changePatientName}
                onClick={() => setShowPatientList(true)}
                ref={inputRef}
              />
            </Form.Group>
            <button className="search-icon">
              <span />
            </button>
          </Form>
        </div>

        {showPatientList && (
          <div className="patient-list" ref={patientListRef}>
            {patients.length > 0 && (
              <ListGroup as="ol" numbered style={{ backgroundColor: "none" }}>
                {patients.map((patient) => (
                  <Link
                    key={patient._id}
                    className="info-card-link"
                    to={"/patients/patient/" + patient.id}
                  >
                    {!linkClicked ? (
                      <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                        onClick={clickPatient}
                      >
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">{patient.name}</div>
                          {patient.phone}
                        </div>
                        <Badge bg="primary" pill>
                          {changeDateFormat(patient.lastVisitDate)}
                        </Badge>
                      </ListGroup.Item>
                    ) : null}
                  </Link>
                ))}
              </ListGroup>
            )}
          </div>
        )}
      </div>
      <button
        className="add-patient btn btn-outline-primary"
        onClick={() => navigate.push("/patients/add")}
        type="button"
      >
        Add Patient
      </button>
    </Fragment>
  );
};

export default SearchBox;
