import { endPoints, coreApi } from "../config/clinimentApi";
import axios from "axios";
import errorHandling from "./ErrorHandling";

const Forgot = {
  forgetPassword: async (data) => {
    try {
      const response = await axios.post(endPoints.forget, data);
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err);
    }
  },
  verifyOtp: async (data) => {
    try {
      const response = await axios.post(endPoints.verifyOtp, data);
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err?.response?.data?.message);
      throw err;
    }
  },
  resetPassword: async (data) => {
    try {
      const response = await axios.post(endPoints.resetPassword, data);
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err?.response?.data?.message);
      throw err;
    }
  }
};

export default Forgot;
