import { coreApi, endPoints } from "../config/clinimentApi";
import { ClinimentAuth } from "./ClinimentAuth";
import errorHandling from "./ErrorHandling";

const Patients = {
  addPatient: async (data) => {
    try {
      const response = await ClinimentAuth.fetchData(
        coreApi + endPoints.createPatient,
        data.id ? "patch" : "post",
        {},
        data
      );
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
    }
  },
  getPatients: async (page, queryData) => {
    const queryParams = {
      page: page.page,
      limit: page.limit
    };
    let query = "?page=" + queryParams.page + "&limit=" + queryParams.limit;

    if (queryData.name) {
      query += "&name=" + queryData.name;
    }
    if (queryData.phone) {
      let phone = queryData.phone;
      if (phone.startsWith("+")) {
        phone = queryData.phone.slice(1);
      }
      query += "&phone=" + phone;
    }

    try {
      const response = await ClinimentAuth.fetchData(
        coreApi + endPoints.getPatients + query,
        "get"
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
    }
  },

  getPatientById: async (id) => {
    try {
      const response = await ClinimentAuth.fetchData(
        coreApi + endPoints.getPatient + "/" + id,
        "get"
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err.response.status);
    }
  },

  getVisitsByPatientId: async (patientId) => {
    try {
      const response = await ClinimentAuth.fetchData(
        coreApi + endPoints.getVisits + "/" + patientId,
        "get"
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
    }
  },

  getVisitByVisitId: async (id) => {
    try {
      const response = await ClinimentAuth.fetchData(
        coreApi + endPoints.getVisit + "/" + id,
        "get"
      );
      return response?.data?.visit || {};
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
    }
  },

  getVisits: async () => {
    try {
      const response = await ClinimentAuth.fetchData(coreApi + endPoints.getVisits, "get");
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
    }
  },

  addVisit: async (data) => {
    try {
      const response = await ClinimentAuth.fetchData(
        coreApi + endPoints.addVisit,
        data._id ? "patch" : "post",
        {},
        data
      );
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
    }
  },

  deletePatient: async (id) => {
    try {
      const response = await ClinimentAuth.fetchData(
        coreApi + endPoints.deletePatient + id,
        "delete"
      );
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
    }
  }
};

export default Patients;
