export const coreApi = process.env.REACT_APP_CORE_API;
export const endPoints = {
  requestPhoneOtp: "v1/auth/request-phone-otp",
  verifyPhoneOtp: "v1/auth/verify-phone-otp",
  login: "v1/auth/login",
  myProfile: "v1/users/user/",
  getAllUsers: "v1/users/users",
  addUser: "v1/auth/register",
  getPatients: "v1/patients/patients",
  getPatient: "v1/patients/patient",
  createPatient: "v1/patients/create",
  getVisits: "v1/visit/visits",
  getVisit: "v1/visit/visit",
  getUsersForDropdown: "v1/users/drop-down-users",
  addVisit: "v1/visit/create",
  uploadFile: "v1/file-upload/upload/",
  deletePatient: "v1/patients/patient/",
  createContact: "v1/contacts/create",
  getContacts: "v1/contacts/contacts",
  deleteContact: "v1/contacts/contact/",
  addSetting: "v1/settings/create",
  getSettings: "v1/settings/settings",
  getSettingById: "v1/settings/setting",
  deleteSettingById: "v1/settings/setting"
};
