import moment from "moment/moment";

const changeDateFormat = (date, isTimeFormat, isAMPM) => {
  if (!date) {
    return "";
  }
  let data = "";
  if (isAMPM) {
    data = moment(date).format(isTimeFormat ? "DD/MM/YYYY hh:mm:ss A" : "DD/MM/YYYY");
  } else {
    data = moment(date).format(isTimeFormat ? "DD/MM/YYYY HH:mm:ss" : "DD/MM/YYYY");
  }
  // const data = moment(date).format(isTimeFormat ? "DD/MM/YYYY HH:mm:ss" : "DD/MM/YYYY");
  if (data !== "Invalid date") {
    return data;
  }
  return "";
};

export default changeDateFormat;
