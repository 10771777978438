import React from "react";
import cx from "classnames";

import { CSSTransition, TransitionGroup } from "react-transition-group";

import HeaderLogo from "../AppLogo";

import UserBox from "../../Components/UserBox";
import SearchBox from "../../Components/SearchBox";

class Header extends React.Component {
  render() {
    return (
      <TransitionGroup>
        <CSSTransition
          component="div"
          className={cx("app-header", "white", { "header-shadow": true })}
          appear
          timeout={1500}
          enter={false}
          exit={false}
        >
          <div>
            <HeaderLogo />

            <div
              className={cx("app-header__content", {
                "header-mobile-open": true
              })}
            >
              <div className="app-header-left">
                <SearchBox />
              </div>
              <div className="app-header-right">
                <UserBox />
              </div>
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

export default Header;
