import { coreApi, endPoints } from "../config/clinimentApi";
import { ClinimentAuth } from "./ClinimentAuth";
import errorHandling from "./ErrorHandling";
const FormData = require("form-data");
const FilesUpload = {
  upload: async (patientId, fileData) => {
    const form = new FormData();
    form.append("file", fileData);
    form.append("name", fileData.name);
    form.append("type", fileData.type);
    form.append("size", fileData.size);
    try {
      const res = await ClinimentAuth.fetchData(
        coreApi + endPoints.uploadFile + patientId,
        "post",
        { "Content-Type": "multipart/form-data;boundary=${form._boundary}" },
        form
      );
      return res;
    } catch (err) {
      errorHandling.handleErrors(err?.response?.data);
    }
  }
};
export default FilesUpload;
