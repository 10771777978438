const routePaths = {
  users: "user-management/users",
  userDetails: "user-management/user/",
  patients: "patients",
  patientById: "patients/patient/",
  visits: "visits",
  contacts: "contacts",
  settings: "settings-list"
};
const docrorRoutes = [
  routePaths.users,
  routePaths.userDetails,
  routePaths.patients,
  routePaths.patientById,
  routePaths.visits,
  routePaths.contacts,
  routePaths.settings
];
const allMenuRoutes = [
  routePaths.users,
  routePaths.userDetails,
  routePaths.patients,
  routePaths.patientById,
  routePaths.visits,
  routePaths.contacts,
  routePaths.settings
];
const routes = {
  allMenuRoutes,
  docrorRoutes
};
export default routes;
