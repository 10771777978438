import React from "react";
import cx from "classnames";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import propTypes from "prop-types";

const PageTitle = (props) => {
  PageTitle.propTypes = {
    heading: propTypes.string,
    icon: propTypes.any,
    subheading: propTypes.string,
    url: propTypes.string,
    profilePic: propTypes.string
  };
  const history = useHistory();
  const { heading, icon, subheading, url, profilePic } = props;

  function goBack() {
    history.goBack();
  }

  return (
    <div className="app-page-title">
      {url ? (
        <Button className="back-button btn-light fs-3" onClick={goBack}>
          <i className="pe-7s-back"></i>
        </Button>
      ) : null}
      <div className="page-title-wrapper">
        <div className="page-title-heading">
          <div className={cx("page-title-icon", { "d-none": false })}>
            {profilePic ? (
              <a href={profilePic} target="_blank" rel="noreferrer">
                {" "}
                <img className="avatar" width={30} alt="" src={profilePic} />
              </a>
            ) : (
              <i className={icon} />
            )}
          </div>
          <div>
            {heading}
            <div className={cx("page-title-subheading", { "d-none": false })}>{subheading}</div>
          </div>
        </div>
        <div className="page-title-actions" />
      </div>
    </div>
  );
};

export default PageTitle;
