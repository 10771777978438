import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import Cookies from "js-cookie";
import Spinner from "../../services/Spinner";
import PrivateRoute from "../../services/Authentication/PrivateRoute";
import AppHeader from "../AppHeader";
import AppSidebar from "../AppSidebar";
import AppFooter from "../AppFooter";
import Visits from "../../PortalPages/Patients/Visits";

const Users = lazy(() => import("../../PortalPages/Users"));
const Patients = lazy(() => import("../../PortalPages/Patients"));
const Contacts = lazy(() => import("../../PortalPages/Contacts"));
const Settings = lazy(() => import("../../PortalPages/Settings"));

function AppMain() {
  const accessToken = Cookies.get("authToken");
  return (
    <>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Suspense
              fallback={
                // <div className="loader-container">
                <div className="loader-container-inner">
                  <Spinner />
                </div>
                //  </div>
              }
            >
              <PrivateRoute path="/user-management" component={Users} />
              <PrivateRoute path="/patients" component={Patients} />
              <PrivateRoute path="/visits" component={Visits} />
              <PrivateRoute path="/contacts" component={Contacts} />
              <PrivateRoute path="/settings-list" component={Settings} />
            </Suspense>
          </div>
        </div>
      </div>
      <AppFooter />
      <Route
        exact
        path="/"
        render={() =>
          accessToken ? <Redirect to="/user-management/users" /> : <Redirect to="/login" />
        }
      />
    </>
  );
}

export default AppMain;
