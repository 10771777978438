import moment from "moment";

const checkStage = (data, stage) => {
  if (data.i_am === "2" && data.due_date_parent !== "" && data.due_date_parent !== null) {
    if (moment(data.due_date_parent, "YYYY-MM-DD").isValid()) {
      return stage;
    }
  }
  if (
    (data.i_am === "3" || data.i_am === "5") &&
    data.no_of_children !== "" &&
    data.no_of_children !== null &&
    data.no_of_children !== 0
  ) {
    return stage;
  }
  if (
    data.i_am === "4" &&
    ((data.no_of_children !== "" && data.no_of_children !== null && data.no_of_children !== 0) ||
      (data.due_date_parent !== "" && data.due_date_parent !== null))
  ) {
    if (moment(data.due_date_parent, "YYYY-MM-DD").isValid()) {
      return stage;
    }
  }
  if (data.i_am === "1") {
    return stage;
  }
  return "L1";
};
const ClinimentFunctions = {
  setLevel(data) {
    if (data.user_status === 1) {
      if (
        data.email !== "" &&
        data.email !== null &&
        data.i_am !== "" &&
        data.i_am !== null &&
        data.pincode !== "" &&
        data.pincode !== null
      ) {
        return checkStage(data, "L3");
      }
      if (data.email !== "" && data.email !== null && data.i_am !== "" && data.i_am !== null) {
        return checkStage(data, "L2");
      }
      if (data.email !== "" || data.email !== null) {
        return "L1";
      }
    }
  },

  getStageName: (data) => {
    switch (data) {
      case "1":
        return "Planning";
      case "2":
        return "Pregnant";
      case "3":
        return "Parent";
      case "4":
        return "Parent and Pregnant";
      case "5":
        return "Parent and Planning";
      default:
        return "";
    }
  },
  getStages: () => [
    { value: "1", label: ClinimentFunctions.getStageName("1") },
    { value: "2", label: ClinimentFunctions.getStageName("2") },
    { value: "3", label: ClinimentFunctions.getStageName("3") },
    { value: "4", label: ClinimentFunctions.getStageName("4") },
    { value: "5", label: ClinimentFunctions.getStageName("5") }
  ],
  getStagesForFilters: () => [
    { value: "", label: "All" },
    { value: "1", label: ClinimentFunctions.getStageName("1") },
    { value: "2", label: ClinimentFunctions.getStageName("2") },
    { value: "3", label: ClinimentFunctions.getStageName("3") },
    { value: "4", label: ClinimentFunctions.getStageName("4") },
    { value: "5", label: ClinimentFunctions.getStageName("5") },
    { value: "noStage", label: "No Stage" }
  ],
  getMainStages: () => {
    return [
      { value: "1", label: ClinimentFunctions.getStageName("1") },
      { value: "2", label: ClinimentFunctions.getStageName("2") },
      { value: "3", label: ClinimentFunctions.getStageName("3") }
    ];
  },

  getUserAccess: (roles) => {
    if (!Array.isArray(roles)) {
      return "";
    }
    for (const role of roles) {
      if (role === "admin") {
        return "/user-management/users";
      } else if (role === "doctor") {
        return "/user-management/users";
      }
    }

    return "";
  },

  getWeeks: (isAllWeeks) => {
    let weekData = Array.from({ length: 37 }, (_, index) => index + 4);
    let allWeeks = [];
    if (isAllWeeks) {
      allWeeks = ["All Weeks", "Trimester 1", "Trimester 2", "Trimester 3"];
    }
    weekData = [...allWeeks, ...weekData];
    return weekData;
  },

  getBabyWeeks: () => {
    const weekData = Array.from({ length: 52 }, (_, index) => index + 1);
    return weekData;
  }
};

export { ClinimentFunctions };
